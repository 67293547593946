import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import water from '../../images/water.png'
import sugar from '../../images/sugar.png'
import paper from '../../images/paper.png'
import barrel from '../../images/barrel.png'
import molecule from '../../images/molecule.png'
import ecology from '../../images/ecology.png'
import service from '../../images/service-excellence.png'
import delivery from '../../images/swift-delivery-time.png'
import quality from '../../images/quality.png'
import map from '../../images/map.png'
import { Helmet } from 'react-helmet'
import moment from 'moment'

const IndexPageRu = () => (
  <Layout>
    <Helmet>
      <title>Химические реагенты IN-ECO</title>
      <meta
        name="description"
        content="IN-ECO - Европейский производитель химических реагентов. Наши продукты используются в промышленной водоподготовке, сахарной индустрии, бумажной промышленности и газо и нефтепромысле."
      />
    </Helmet>
    <div className="cta">
      <div className="bookmark"></div>
      <div className="columns cta-content">
        <div className="column">
          <h1 className="title title-middle cta-content-title">
            IN-ECO это торговая марка химических реагентов
          </h1>
          <h2 className="subtitle cta-content-subtitle">
            Наши реагенты используются в программах промышленной химической
            обработки
          </h2>
          <Link to="/about-us" className="button button-primary">
            Узнать больше
          </Link>
        </div>
        <div className="column has-text-right">
          <div className="title cta-years">
            {moment().diff(moment([2009, 4, 13]), 'years')}
          </div>
          <div className="subtitle cta-content-subtitle subtitle-small">
            Лет успешного сотрудничества
            <br /> с нашими клиентами
          </div>
        </div>
      </div>
    </div>
    <div className="columns cta-categories">
      <Link to="/ru/водоподготовка" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={water} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Химическая
          <br />
          водоподготовка
        </span>
      </Link>
      <Link
        to="/ru/реагенты-сахарного-производства"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={sugar} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Реагенты сахарного
          <br /> производства
        </span>
      </Link>
      <Link
        to="/ru/реагенты-бумажной-промышленности"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={paper} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Реагенты бумажной
          <br /> промышленности
        </span>
      </Link>
      <Link
        to="/ru/реагенты-газо-и-нефтепромысла"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={barrel} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Реагенты газо
          <br /> и нефтепромысла
        </span>
      </Link>
      <Link to="/ru/purolite" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={molecule} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">Смолы Purolite</span>
      </Link>
    </div>
    <div className="advantages">
      <h2 className="title title-large advantages-title">
        Решения IN-<span className="text-accented">ECO</span>
      </h2>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Продукты IN-ECO обеспечивает экологическую безопасность и чистую
            линию производства в промышленности
          </div>
          <div className="content-text advantages-item-text">
            Наши решения гарантируют увеличение эффективности работы
            оборудования, снижение потребления энергии и охрану окружающей среды
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Экология</div>
            <img src={ecology} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Сотрудники IN-ECO являются экспертами с обширными теоретическими
            знаниями и практическим опытом
          </div>
          <div className="content-text advantages-item-text">
            Универсальный подход гарантирует индивидуальное решение каждому
            клиенту
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">
              Квалифицированный сервис
            </div>
            <img src={service} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Производство продукции IN-ECO проводится под наблюдением
            специалистов с более чем 35 летним опытом в производстве реагентов
          </div>
          <div className="content-text advantages-item-text">
            Высокое качество продукции подтверждено сертификатами ISO 9001 и ISO
            14001
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">
              Гарантированное качество
            </div>
            <img src={quality} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Быстрое исполнение и удобное расположение гарантируют оперативную
            доставку нашим клиентам и непрерывность производственного процесса
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Оперативность</div>
            <img src={delivery} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="location">
      <img src={map} alt="" />
      <h4 className="location-title">
        Наш главный офис в <span className="text-accented">Литве</span>
      </h4>
    </div>
  </Layout>
)
export default IndexPageRu
